import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "root",
        component: () => import("../components/Login.vue")
    },
    {
        path: "/overview",
        name: "overview",
        component:()=>import("../components/ListContent.vue")
    }
]

const router = new VueRouter({
    routes,
    mode: process.env.NODE_ENV === 'production' ? 'history' : 'history',
    base: process.env.NODE_ENV === "production" ? "/" : "/"
})

export default router
