<template>
  <b-row style="height: 100vh; width: 99vw">
    <b-col col lg="3" class="navigation">
      <h1 class="mb-4">{{ $t("nav.caption") }}</h1>
      <div v-if="!$loginData.data">
        <p v-html="$t('nav.info')"></p>
      </div>
      <div v-else>
        <b-list-group>
          <b-list-group-item
            v-for="(ship, index) in ships"
            :active="selectedShip === ship"
            :key="index"
            class="b-list-group-item"
            @click="selectedShip = ship; $eventBus.$emit('shipChanged', ship)"
          >
            {{ ship }}
          </b-list-group-item>
        </b-list-group>
        <b-list-group class="mt-4">
          <b-list-group-item
            class="b-list-group-item"
            @click="
              $loginData.data = null;
              $router.push({ path: '/' });
            "
          >
            {{ $t("nav.logout") }}
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-col>
    <b-col class="content">
      <router-view />
    </b-col>
  </b-row>
</template>

<script>
import Content from "./modules/content";

export default {
  name: "App",
  data() {
    return {
      ships: [],
      selectedShip: null,
    };
  },
  methods: {
    loadShips() {
      Content.ships().then((ships) => {
        this.ships = ships;
      });
    },
  },
  created() {
    this.$eventBus.$on("login", () => {
      this.loadShips();
    });
  },
};
</script>

<style>
h1 {
  font-size: 110% !important;
}
h2 {
  font-size: 105% !important;
}
h3 {
  font-size: 100% !important;
}
.b-list-group-item {
  cursor: pointer !important;
}
.navigation {
  color: white;
  background-color: rgb(93, 135, 161);
  padding: 20px !important;
}
.content {
  padding: 20px !important;
}
</style>

