const https = require("http")
const server = {
    host: "www.neische.org",    
    basePath: "/core"
}

module.exports = {
    MAX_SIZE: 4098,
    chunk(str, size) {
        let chunks = []
        let offset = 0
        let len = str.length

        if (len <= size) {
            return chunks.push(str) && chunks
        }
        while (offset < len) {
            chunks.push(str.substr(offset, size))
            offset += size
        }

        return chunks
    },
    check(data, required = []) {
        let stack = true
        if (data) {
            for (let i = 0, len = required.length; i < len; i++) {
                let field = data[required[i]]
                if (field) {
                    if (typeof field === "undefined" || field === null) {
                        stack = false
                    }
                } else {
                    stack = false
                }
            }
        } else {
            stack = false
        }
        return stack

    },
    queryString(params) {
        let query = ""
        if (params) {
            query = "?"
            for (let i = 0, len = params.length; i < len; i++) {
                if (typeof params[i].value !== "undefined") {
                    query += params[i].key + "=" + params[i].value + "&"
                }
            }
            query = query.substring(0, query.length - 1)
        }
        return query
    },
    request(options, data, progress) {
        return new Promise((resolve, reject) => {
            const req = https.request(options, res => {
                if (res.statusCode === 200 || res.statusCode === 201) {
                    let _data = []
                    let _length = 0
                    res.on("data", chunk => {
                        _data.push(chunk)
                        _length += chunk.length
                    })
                    res.on("end", () => {
                        const _buffer = Buffer.alloc(_length)
                        for (let i = 0, _len = _data.length, _pos = 0; i < _len; i++) {
                            _data[i].copy(_buffer, _pos)
                            _pos += _data[i].length
                        }
                        let result = { status: res.statusCode, }
                        try {
                            result.data = _buffer.length ? options.convert === 'json' ? JSON.parse(_buffer.toString()) : options.convert === 'string' ? _buffer.toString() : _buffer : null
                        } catch (e) {
                            console.log(e)
                        }
                        resolve(result)
                    })
                } else {
                    let _data = []
                    let _length = 0
                    let result = null
                    res.on("data", chunk => {
                        _data.push(chunk)
                        _length += chunk.length
                    })
                    res.on("end", () => {
                        const _buffer = Buffer.alloc(_length)
                        for (let i = 0, _len = _data.length, _pos = 0; i < _len; i++) {
                            _data[i].copy(_buffer, _pos)
                            _pos += _data[i].length
                        }
                        try {
                            if (_length)
                                result = _buffer.toString()
                        } catch (e) {
                            console.log(e)
                            result = _buffer.toString()
                        }
                        reject({
                            status: res.statusCode,
                            err: result
                        })
                    })
                }
            })
            if (req) {
                req.on("error", err => {
                    reject({
                        status: -2,
                        error: err
                    })
                })
                if (data) {

                    let _sendData
                    if (options.formData) {
                        _sendData = data
                    } else {
                        if (typeof data === "string") {
                            _sendData = data
                        } else if (typeof data === "object") {                            
                            _sendData = JSON.stringify(data)
                        }
                    }

                    if (_sendData) {
                        const total = _sendData.length
                        const _chunks = this.chunk(_sendData, this.MAX_SIZE)
                        let loaded = 0

                        for (let i = 0, len = _chunks.length; i < len; i++) {
                            req.write(_chunks[i])
                            if (progress) {
                                loaded += _chunks[i].length
                                setTimeout(() => {
                                    progress.progress = Math.ceil((loaded / total) / 100)
                                })
                            }
                        }
                    }
                }
                req.end()
            } else {
                reject({
                    status: -1
                })
            }
        })
    },
    get(params, options = {}) {
        return new Promise((resolve, reject) => {
            if (options.path) {
                let query = this.queryString(params)
                this.request({
                    method: "GET",
                    host: options.host ? options.host : server.host,
                    path: server.basePath + options.path + query,
                    convert: options.convert ? options.convert : "json"
                }).then(response => {
                    resolve(response ? response.data : null)
                }).catch(err => {
                    reject(err)
                })
            } else {
                reject("Path required!")
            }
        })
    },
    post(data, options = {}) {
        return new Promise((resolve, reject) => {
            if (options.path && this.check(data, options.required)) {
                this.request({
                    method: "POST",
                    host: options.host ? options.host : server.host,
                    path: server.basePath + options.path,
                    headers: {
                        "content-type": "application/json"
                    },
                    convert: options.convert ? options.convert : "json"
                }, data).then(response => {
                    resolve(response ? response.data : null)
                }).catch(err => {
                    reject(err)
                })
            } else {
                reject("Path required!")
            }
        })
    },
    queryPost(params, data, options = {}) {
        return new Promise((resolve, reject) => {
            if (options.path && this.check(data, options.required)) {
                let query = this.queryString(params)
                this.request({
                    method: "POST",
                    host: options.host ? options.host : server.host,
                    path: server.basePath + options.path + query,
                    headers: {
                        "content-type": "application/json"
                    },
                    convert: options.convert ? options.convert : "json"
                }, data).then(response => {
                    resolve(response ? response.data : null)
                }).catch(err => {
                    reject(err)
                })
            } else {
                reject("Path required!")
            }
        })

    }
}