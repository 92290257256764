import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueCookie from "vue-cookie"
import router from "./routes/router"

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'



Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCookie)
Vue.use(VueI18n)

Vue.prototype.$loginData = {}
Vue.prototype.$eventBus = new Vue()

const messages = require("./i18n/defaults.json")
const i18n = new VueI18n({
  locale: "de",
  messages
})


new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
