const Client = require("./abstractClient")

module.exports = {
    ships() {
        return Client.get(
            null,
            {
                path: "/content/ships"
            })
    },
    shipContent(ship) {
        return Client.get(
            [
                {
                    key: "ship",
                    value: ship
                }
            ],
            {
                path: "/content/ships/content"
            }
        )
    }
}